body {
  font-family: 'Exo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 240px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* a {
  color: inherit;
  background-color: inherit;
} 

/* width */
::-webkit-scrollbar {
  width: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--hd-color); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--hd-color); 
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@media (min-width: 475px) {
  html {
    font-size: 18px;
  }
}

h2 {
  margin: 0.5em 0;
  font-weight: 900;
}


@media (min-width: 845px) {
  html {
    font-size: 20px;
  }
}

*:focus {
  outline: dashed 0.15rem #bb1450 !important;
}

:root {
  /* colors */
  --hd-color: #bb1450;
  
  /* margins */
  --mg-25: 0.25em;
  --mg-5: 0.5em;
  --mg-10: 1em;
  --mg-20: 2em;
  --mg-ctr: 0 auto;

  /* font sizes */
  --fs-5: 0.5rem;
  --fs-10: 1rem;
  --fs-20: 2rem;
  --fs-30: 3rem;

  /* positioning */
  --pos-025: 0.25em;
  --pos-05: 0.5em;
  --pos-10: 1em;
  --pos-20: 2em;
  --pos-30: 3em;
}

/* loader */
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, var(--hd-color), #000);
}

.no-scroll {
  overflow: hidden;
}

.main-container {
  transition: all 0.3s linear;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

@media (min-width: 750px) {
  .logo {
    left: 20px;
    transform: translateY(-50%);
  }
}
/* header -----------------------------------------*/
.header {
  position: relative;
  height: 4em;
  border-bottom: 2px solid;
  z-index: 1000;
}

.header img {
  /* width: 80px; */
  cursor: pointer;
}

/* theme-switch */
.theme-container {
  padding: 0.3rem;
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 2.1rem;
  height: 3.3rem;
  position: relative;
  border-radius: 1rem;
  cursor: pointer;
}

.sun {
  color: yellow;
}

.moon {
  color: #fff;
}

.darkContainer {
  background-color: #aaa;
}

.switch {
  background-color: #222;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  border: 2px solid var(--hd-color);
  position: absolute;
  /* transform: translate(-0.1rem, 0.7rem); */
  transform: translateY(0.7rem);
  transition: transform 0.2s linear;
  /* display: none; */
}

.move {
  transform: translateY(-0.7rem);
  border: 2px solid #fff;
  background-color: #aaa;
}

.bright {
  color: #000;
  background-color: #f3eee4;
}

.bright-color {
  color: #000;
}

/* light & dark classes */
.dark {
  color: #fff;
  background-color: #000;
}
.dark-color {
  color: #fff;
}

/* hamburger */
.hamburger-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--pos-05);
  color: inherit;
  cursor: pointer;
  z-index: 2000;
}

.hamburger-container:visited {
  border: 1px solid red;
}

@media (min-width: 750px) {
  .hamburger-container {
    display: none;
  }
}

/* links menu */
.menu-icon {
  font-size: var(--fs-20);
}

/* menu modal */
.modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 0%;
  transition: all 0.3s ease-in-out 0s;
  z-index: 200;
}

.full-modal-container {
  height: 100vh;
}

.modal-content {
  display: none;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* opacity: 0; */
  height: 40%;
  font-size: 1.75rem;
  font-weight: 900;
  transition: all 0.5s ease 0s;
  z-index: 500;
}

.full-modal-content {
  display: flex;
}

.modal-bright {
  color: #000;
  background-color: #fff;
}

.li-bright-color {
  color: #000;
}

/* light & dark classes */
.modal-dark {
  color: #fff;
  background-color: #000;
}
.li-dark-color {
  color: #fff;
}

@media (min-width: 750px) {
  .modal-container {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 40%;
    height: auto;
    transition: none;
  }
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 900;
    transition: none;
  }

  .modal-container a {
    padding: 0.5em 0.75em;
    color: inherit;
  }

  .modal-bright {
    color: inherit;
    background-color: inherit;
  }

  .li-bright-color {
    color: inherit;
  }

  /* light & dark classes */
  .modal-dark {
    color: inherit;
    background-color: inherit;
  }
  .li-dark-color {
    color: inherit;
  }
}

@media (orientation: landscape) {
  .modal-content {
    justify-content: space-around;
    font-weight: 400;
    height: 60%;
  }
}
ul a {
  text-decoration: none;
  color: inherit;
  font-weight: 900;
}

/* links-container */
.link {
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: var(--hd-color);
  border-bottom: 1px solid var(--hd-color);
}

.no-links {
  display: none;
}

/* arrow icon */
.up-icon {
  font-size: var(--fs-20);
}

.fixed-container-arrow {
  position: fixed;
  right: var(--pos-025);
  bottom: 3rem;
  z-index: 199;
}

.fixed-container-switch {
  position: fixed;
  left: var(--pos-025);
  bottom: var(--pos-30);
  z-index: 10000;
}

/* main ---------------------------------------- */
/* content container */
.content-container {
  width: 95%;
  margin: auto;
  text-align: center;
}

@media (min-width: 321px) {
  .content-container {
    width: 80%;
  }
}

@media (min-width: 750px) {
  .content-container {
    width: 85%;
    text-align: center;
  }
}

@media (min-width: 845px) {
  .content-container {
    width: 75%;
    text-align: center;
  }
}

/* intro */
.intro {
  margin-top: var(--mg-20);
}

.intro h1 {
  font-size: 3.4375rem;
}

.bio-a {
  color: var(--hd-color);
  text-decoration: underline;
  font-weight: 900;
}

@media (min-width: 750px) {
  .intro {
    display: flex;
    justify-content: space-between;
  }
}

/* intro image */
.bio-image {
  display: block;
  width: 80%;
  min-width: 200px;
  max-width: 250px;
  height: auto;
  min-height: 200px;
  max-height: 250px;
  border: 10px solid var(--hd-color);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 1.5em;
}

.bio-image:hover {
  border-radius: 0;
}

@media (min-width: 750px) {
  .bio-image {
    max-width: 330px;
    max-height: 330px;
    margin-left: 2em;
    margin-bottom: auto;
    order: 1;
  }
}

/* caption */
div .caption {
  text-align: center;
}

@media (min-width: 750px) {
  div .caption {
    text-align: left;
  }
}

/* section header */
.section-header {
  font-weight: 900;
  margin-top: 0.5em;
  padding: 0.25em 0.5em;
  background-color: var(--hd-color);
  display: inline-block;
  border-radius: 0 1.28em 0 1.28em;
  text-transform: uppercase;
  color: #fff;
}

/* title */
.title {
  margin-top: 1em;
}

.title * {
  display: inline-block;
}

.brief-bio {
  line-height: 1.5em;
  margin-top: 1em;
}

.proj {
  cursor: not-allowed;
}

.caption a {
  text-decoration: none;
}

.btn {
  display: inline-block;
  font-family: 'Exo', serif;
  font-size: 1em;
  font-weight: 900;
  margin: 1em auto auto auto;
  padding: 0.75em 0;
  border-radius: 0.375em;
  background-color: var(--hd-color);
  border: 2px solid var(--hd-color);
  color: #fff;
  border: none;
  width: 80%;
  max-width: 250px;
  cursor: pointer;
  box-shadow: 0px 9px 29px rgba(252, 53, 73, 0.22);
}

.flexed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn .sideIcon {
  margin-left: 0.5em;
}

.btn:hover {
  background-color: #fff;
  color: var(--hd-color);
}

.btn:active {
  background-color: var(--hd-color);
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 0 0 5px lightskyblue;
}

/* Bio */
.about {
  text-align: left;
  margin-top: 2em;
  line-height: 1.5em;
}
.about h2 {
  margin: 0.5em auto;
  font-weight: 900;
}

.about p {
  margin-bottom: 0.5em;
}

.about span {
  margin-bottom: 0.5em;
  display: inline-block;
}

.icon-animate {
  animation: star 2s infinite;
}

@keyframes star {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
    transform: scale(1.3);
  }
  75% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.social-icons-wrapper {
  margin-bottom: 1em;
}
.social-icons-wrapper a {
  color: #000;
}

.sm-font {
  font-size: 0.75rem;
}

.bio-icon-container {
  width: 50%;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bio-icon-container a {
  height: 85px;
  display: flex;
  flex-direction: column;
  width: 60px;
  justify-content: center;
  align-items: center;
}

.bio-icon {
  font-size: var(--fs-20);
  transition: 0.3s all;
}

.icon-text {
  font-size: 0.6rem;
}

.bio-icon:hover {
  color: var(--hd-color);
}

.light {
  color: #fff;
}

/* Skills & Tools */
.skills {
  margin-bottom: 2em;
  min-width: 256px;
  max-width: 900px;
  margin: 0 auto;
}

.icon-wrapper {
  font-size: 2.8rem;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
}

.icon-wrapper span {
  font-size: 0.6rem;
  font-weight: 900;
}

@media (min-width: 540px) {
  .icon-wrapper {
    font-size: 3.5rem;
  }
  .icon-wrapper span {
    font-size: 0.8rem;
  }
}
@media (min-width: 620px) {
  .icon-wrapper {
    font-size: 4rem;
  }
  .icon-wrapper span {
    font-size: 1rem;
  }
}
@media (min-width: 710px) {
  .icon-wrapper {
    font-size: 4.5rem;
  }
  .icon-wrapper span {
    font-size: 1.2rem;
  }
}
@media (min-width: 760px) {
  .icon-wrapper {
    font-size: 5.5rem;
  }
  .icon-wrapper span {
    font-size: 1.5rem;
  }
}
@media (min-width: 845px) {
  .icon-wrapper {
    font-size: 4.5rem;
  }
  .icon-wrapper span {
    font-size: 1.2rem;
  }
}

.icon-wrapper span {
  font-size: 0.6rem;
}

.skill-icons {
  margin: 1em auto auto auto;
  max-width: 800px;
}

.icon-set {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* width: 100%; */
  min-width: 256px;
  max-width: 710px;
  margin: auto;
}

@media (max-width: 379px) {
  .icon-set {
    justify-content: space-around;
  }
}

/* contact form */
form {
  margin-bottom: 4em;
  text-align: left;
}

form > p,
.name-email-wrapper > p {
  margin-top: 1em;
}

.name-email-wrapper input,
textarea {
  width: 100%;
  background-color: #ccc;
  color: #000;
  border-width: 2px;
  border-color: transparent transparent rgba(0, 0, 0, 0.3) transparent;
  padding: 0.5em;
  outline: none;
  font-family: 'Exo', sans-serif;
}

.name-email-wrapper input:focus,
textarea:focus {
  border-color: var(--hd-color);
}

label {
  /* color: #555; */
  font-size: 0.8rem;
  font-weight: 900;
}

.form-btn-margin {
  display: block;
  margin: 2em auto auto auto;
}

/* project */
.projects-link-container {
  margin: 2em 0;
  list-style: none;
  text-align: left;
}

.projects-link-container ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-link {
  transition: all 0.3s linear;
}

.project-link:hover {
  transform: translate(1rem);
}

@media (min-width: 750px) {
  .projects-link-container ul {
    margin-left: 4rem;
  }
}

.projects-link-container li {
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 0.5em;
  display: inline-block;
}

.contact {
  max-width: 660px;
  margin: auto;
}

@media (min-width: 768px) {
  .formstyle {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .name-email-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .right,
  .left {
    width: 300px;
  }
  .right {
    float: right;
  }
}

.copy {
  cursor: pointer;
  transition: all 0.3s linear;
  font-size: 1.2rem;
}

.link-email {
  color: var(--hd-color);
  text-decoration: underline;
}

.email-tooltip {
  position: relative;
}

.tooltip {
  position: absolute;
  left: 10px;
  padding: 0.25em 0.5em;
  border-radius: 5px;
  top: -40px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 0.6rem;
}

.email-tooltip:hover .tooltip {
  visibility: visible;
}

p a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s linear;
}

p a:hover {
  cursor: pointer;
}

.alt-border-curves {
  animation-duration: 6s;
  animation-name: border-curves;
}

@keyframes border-curves {
  0% {
    border-radius: 0 1.28em 0 1.28em;
  }
  25% {
    border-radius: 1.28em 0 1.28em 0;
  }
  50% {
    border-radius: 0 1.28em 0 1.28em;
  }
  75% {
    border-radius: 1.28em 0 1.28em 0;
  }
  100% {
    border-radius: 0 1.28em 0 1.28em;
  }
}

/* blog */
.sort {
  display: flex;
  flex-direction: column-reverse;
}